@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes progress {
  0% {
    width: 0%;
  }
  50% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}